<template>
  <div v-animate-css="{
        classes: 'fadeIn',
        duration: 1000,
      }">
    <div class="text-center mb-4">
      <h4>{{ question.order }}. {{ question.title }}</h4>
      <p>{{ question.description }}</p>
    </div>
    <div class="mb-3 d-flex align-items-center">
      <img src="../../assets/icons/info.png" width="30px" height="30px" alt="">
      <p class="fw-bold mb-0">{{ this.$store.getters.getUsdText }}</p>
    </div>
    <div v-for="(input, i) in question.options.values" :key="input.order">
      <div class="form-group position-relative mb-4 wet-form input px-3 ">
        <label class="mb-2 label fw-bold">{{ input.text }}</label>
        <br>
        <vue-numeric  currency="$" class="input-currency" separator="." placeholder="$0"  v-model="result[i]" @change="result[i] = $event.target.value"></vue-numeric>
<!--        <CurrencyInput v-show="false" ref="projections" class="input-currency" v-model="result[i]"  @change="result[i] = $event" :options="{ currency: 'USD' }"/>-->
<!--        <input class="form-control dollar-input" :value="result[i]" @input="handleInput($event.target.value, i )">-->
<!--        <img src="../../assets/icons/dollar-symbol.png"-->
<!--             class="currency-icon" width="20px" alt="">-->
      </div>
    </div>
    <div class=" buttons row mt-5 ">
      <div class="col-6">
        <button class="btn btn-outline-success w-100 controls-button" @click="handlePrev">
          {{ $store.getters.getPrevText }}
        </button>
      </div>
      <div class="col-6">
        <button class="btn btn-outline-success w-100 controls-button"  @click="handleNext">
          {{$store.getters.getNextText}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {ProjectionsInitialData} from "../../utils/questionsInitialData";
import store from "../../store";
import Vue from 'vue';
import VueNumeric from 'vue-numeric'
import VAnimateCss from 'v-animate-css';

Vue.use(VAnimateCss);

export default {
  name: "Projections",
  components: {
    // CurrencyInput: () => import('../CurrencyInput'),
    VueNumeric,
  },
  props: {},
  data() {
    return {
      price: '',
      question: ProjectionsInitialData,
      result: [],
    }
  },
  mounted() {
  },
  watch: {
    '$store.state.currentQuestion': function () {
      this.question = store.state.currentQuestion;
      if (store.state.currentQuestion.result.answer !== null) {
        this.result = store.state.currentQuestion.result.answer
        console.log(this.result);
      } else {
        const length = store.state.currentQuestion.options.values.length;
        const arr = []
        for (let i = 0; i < length; i++) {
          arr.push('');
        }
        this.result = arr;
      }
    }
  },
  methods: {
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      // return Number(x).toLocaleString();
    },
    async handleNext() {
      const data = {
        idQuestion: store.getters.getCurrentQuestion._id,
        orderQuestion: store.getters.getCurrentQuestion.order,
        section: 'valorization',
        idUser: JSON.parse(localStorage.userData)._id,
        email: JSON.parse(localStorage.userData).email,
        answer: this.result
      }
      this.$emit('sendQuestion', data);
    },
    handlePrev() {
      this.$emit('goPrev');
    },
    getQuestionData() {
      if (store.state.currentQuestion.type === 'multipleWithImages') {
        this.question = store.state.currentQuestion;
      }
    },
    handleInput(e, index) {
      console.log(e, index)
      this.result[index] = e;
    }
  },

}
</script>

<style scoped lang="scss">
.dollar-input {
  width: 50%;
  padding-left: 2.5rem;

}

.input-currency {
  width: 50%;
  height: 38px;
  padding: 1rem;
  &:focus-visible {
    outline: none !important;
  }
}

.currency-icon {
  position: absolute;
  top: 62%;
  left: 3%;
}

.icon {
  fill: #23AF4D;
}

.controls-button {
  &:hover {
    .icon {
      fill: white;
    }
  }
}

.input {
  width: 100%;
}

.label {
  width: auto;
  font-size: 1.3rem
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.buttons {
  button {
    margin: 0 0.5rem;

    &:disabled {
      filter: grayscale(100%);
      opacity: 0.6;
      pointer-events: none;
    }
  }
}

@media (max-width: 700px) {

  .input-currency {
    width: 100%;
  }
  .currency-icon {
    left: 6%;
  }
}
</style>
